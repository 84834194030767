@if (((matchday$ | async)?.number|| 0) > 1) {
<div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 grid-rows-1 gap-4 md:gap-6 lg:gap-8 items-start">
  @for (role of roles; track role.role.id) {

  <mat-card appearance="outlined">
    <app-player-image
      mat-card-image
      inCard
      [member]="role.best_players?.first"
    />
    @if (!role.best_players) {
    <mat-progress-bar mode="indeterminate" />
    }
    <mat-card-header>
      <mat-card-title>
        @if (role.best_players) {
        <span class="mat-text-primary">
          {{role.best_players.first.ratings[0]?.points}}
        </span>
        {{role.best_players.first.player.full_name}}
        } @else {
        <span class="mat-text-primary">-</span>
        }
      </mat-card-title>
      <mat-card-subtitle>{{role.role.singolar}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            MOSTRA DI PIU'
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          @if (role.best_players) {
          <mat-list dense>
            @for (member of role.best_players.others; track member.id) {
            <mat-list-item>
              <a
                matLine
                [routerLink]="['/players', member.player.id]"
              >
                {{member.player.full_name}}: {{member.ratings[0]?.points}}
              </a>
            </mat-list-item>
            }
          </mat-list>
          }
        </ng-template>
      </mat-expansion-panel>
    </mat-card-content>
  </mat-card>

  }
</div>
} @else {

<app-mat-empty-state
  description="Buona asta!"
  icon="wb_sunny"
  label="La stagione non è ancora iniziata"
  rounded
/>

}