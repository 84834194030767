@if ((stream$ | async); as stream) {

<div @createBox>
  <button
    aria-label="open"
    mat-icon-button
    type="button"
    (click)="open($event.target)"
  >
    <mat-icon
      aria-hidden="false"
      matBadgeColor="accent"
      matBadgeOverlap="true"
      matBadgePosition="above after"
      matBadgeSize="small"
      [matBadge]="(stream.unseen | number: '1.0-0') || ''"
      [matBadgeHidden]="stream.unseen === 0"
    >notifications</mat-icon>
    <ng-template #container />
  </button>
</div>

}
